<template>
  <div>

    <b-button
      v-b-tooltip.hover.bottomleft
      variant="flat-secondary"
      class="btn-icon border-radius-none"
      :title="$t('Anterior')"
      :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
      :disabled="footerStepNavArrowPrevDisable===true"
      @click.stop.prevent="setStepContent('left')"
    >
      <feather-icon
        :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        :size="sizeIconsAction"
      />
    </b-button>

    <b-button
      v-b-tooltip.hover.bottomleft
      variant="flat-secondary"
      class="btn-icon border-radius-none"
      :title="$t('Seguinte')"
      :class="{ 'btn-sm' : ((this.$store.getters['app/currentBreakPoint'] === 'xs') ? true: false)}"
      :disabled="footerStepNavArrowNextDisable===true"
      @click.stop.prevent="setStepContent('right')"
    >
      <feather-icon
        :icon="$store.state.appConfig.isRTL ? 'ChevronLeftIcon' : 'ChevronRightIcon'"
        :size="sizeIconsAction"
      />
    </b-button>

  </div>
</template>

<script>
import {
  VBTooltip, BButton,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  data() {
    return {
      footerStepNavArrowPrevDisable: false,
      footerStepNavArrowNextDisable: false,
    }
  },
  methods: {
    updateStatusArrowNavDisableFooter(currentTab, totalTabs) {
      if (currentTab === 0) {
        this.footerStepNavArrowPrevDisable = true
        this.footerStepNavArrowNextDisable = false
      } else if (currentTab === (totalTabs - 1)) {
        this.footerStepNavArrowPrevDisable = false
        this.footerStepNavArrowNextDisable = true
      } else {
        this.footerStepNavArrowPrevDisable = false
        this.footerStepNavArrowNextDisable = false
      }
    },
    setStepContent(position) {
      this.$root.$emit('bv::hide::tooltip')

      setTimeout(() => {
        if (position === 'left') {
          this.$parent.$parent.$parent.previousTab()
        } else {
          this.$parent.$parent.$parent.nextTab()
        }
      }, 150)
    },
  },
}
</script>
